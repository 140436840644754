import swCore from './index';
import each from 'keen-core/lib/utils/each';
import extend from 'keen-core/lib/utils/extend';
import {initEventManager} from "./event-manager";
import {listenerCore} from './utils/listener';
import {
    recordEvent,
    recordEvents
} from './record-events-browser';
import {
    deferEvent,
    deferEvents,
    queueCapacity,
    queueInterval,
    recordDeferredEvents
} from './defer-events';
import {extendEvent, extendEvents} from './extend-events';
import {initAutoTrackingCore} from './browser-auto-tracking';
import {getBrowserProfile} from './helpers/getBrowserProfile';
import {getDatetimeIndex} from './helpers/getDatetimeIndex';
import {getDomainName} from './helpers/getDomainName';
import {getDomNodePath} from './helpers/getDomNodePath';
import {getDomNodeProfile} from './helpers/getDomNodeProfile';
import {getScreenProfile} from './helpers/getScreenProfile';
import {getScrollState} from './helpers/getScrollState';
import {getUniqueId} from './helpers/getUniqueId';
import {getWindowProfile} from './helpers/getWindowProfile';
import {cookie} from './utils/cookie';
import {deepExtend} from './utils/deepExtend';
import {serializeForm} from './utils/serializeForm';
import {timer} from './utils/timer';
import {setOptOut} from './utils/optOut';
import {isLocalStorageAvailable} from './utils/localStorage';
import MobileDetect from 'mobile-detect'

// ------------------------
// Methods
// ------------------------
extend(swCore.prototype, {
    recordEvent,
    recordEvents,
    initEventManager
});

extend(swCore.prototype, {
    deferEvent,
    deferEvents,
    queueCapacity,
    queueInterval,
    recordDeferredEvents,
    setOptOut
});
extend(swCore.prototype, {
    extendEvent,
    extendEvents
});

// ------------------------
// Auto-Tracking
// ------------------------
const initAutoTracking = initAutoTrackingCore(swCore);
extend(swCore.prototype, {
    initAutoTracking
});

// ------------------------
// Helpers
// ------------------------
extend(swCore.helpers, {
    getBrowserProfile,
    getDatetimeIndex,
    getDomainName,
    getDomNodePath,
    getDomNodeProfile,
    getScreenProfile,
    getScrollState,
    getUniqueId,
    getWindowProfile,
    eventManager: {
        currentElement: null
    }
});

// ------------------------
// Utils
// ------------------------
const listener = listenerCore(swCore);
extend(swCore.utils, {
    cookie,
    deepExtend,
    listener,
    serializeForm,
    timer
});

swCore.listenTo = (listenerHash) => {
    each(listenerHash, (callback, key) => {
        let split = key.split(' ');
        let eventType = split[0],
            selector = split.slice(1, split.length).join(' ');
        // Create an unassigned listener
        return listener(selector).on(eventType, callback);
    });
};

export let keenGlobals = undefined;
if (typeof webpackKeenGlobals !== 'undefined') {
    keenGlobals = webpackKeenGlobals;
}

if (isLocalStorageAvailable && localStorage.getItem('optout')) {
    swCore.optedOut = true;
}

if (navigator.doNotTrack === '1'
    || navigator.doNotTrack === 'yes') {
    swCore.doNotTrack = true;
}

swCore.resources = {
    'base': '{protocol}://{host}',
    'version': '{protocol}://{host}/{version}',
    'projects': '{protocol}://{host}/{version}/projects',
    'projectId': '{protocol}://{host}/{version}/projects/{projectId}',
    'events': '{protocol}://{host}/{version}/projects/{projectId}/events',
    'recording': '{protocol}://{host}/{version}/projects/{projectId}/recording',
    'queries': '{protocol}://{host}/{version}/projects/{projectId}/queries'
}

swCore.helpers.getBrowserProfile()
swCore.on('client', function (client) {
    const md = new MobileDetect(window.navigator.userAgent);
    client.initEventManager(swCore);
    client.extendEvents(function () {
        return {
            tech: {
                device_type: md.tablet() ? 'tablet' : md.mobile() ? 'mobile' : 'desktop'
            }
        };
    });
})

export const schubwerk = swCore.extendLibrary(swCore); // deprecated, left for backward compatibility
export const SchubwerkTracking = schubwerk;
export default schubwerk;
