export function initEventManager(lib) {
    const utils = lib.utils;
    const helpers = lib.helpers;
    lib.doNotTrack = true;
    console.log(document.referrer);
    if (window.location !== window.parent.location && document.referrer ==="https://tracker.schubwerk.de/") {
        window.addEventListener('message',(event)=>{
            const message = event.data.message;
            if (message==='allClickEvents') {
                console.log('onAllClickEvents');
                console.log(event.data.events);
                const events=event.data.events
                events.forEach((trackedEvent)=>{
                    const clickElement=document.querySelector(parseElement(trackedEvent.node_selector));
                    const outlineElement = document.createElement('div');
                    const percentSpan = document.createElement('span');
                    clickElement.parentElement.appendChild(outlineElement)
                    outlineElement.appendChild(clickElement)
                    outlineElement.appendChild(percentSpan)
                    percentSpan.innerHTML=trackedEvent.clicks
                    outlineElement.style.outline = 'solid thick rgb(0,174,143)';
                })
            }
            if (message==='activateNewEventSelection') {
                console.log('New event selection activated')
                utils.listener('*').on('mouseover', function (e) {
                    e.target.style.outline = 'thick solid rgb(0, 174, 143)'
                })
                utils.listener('*').on('mouseout', function (e) {
                    if (helpers.eventManager.currentElement === e.target) return;
                    e.target.style.outline = null
                })
                utils.listener('*').on('click', function (e) {
                    if (helpers.eventManager.currentElement) {
                        helpers.eventManager.currentElement.style.outline = null;
                    }
                    e.preventDefault();
                    helpers.eventManager.currentElement = e.target;
                    let el = helpers.getDomNodeProfile(e.target);
                    top.postMessage({ event: 'selectDOM', element: el }, '*');
        
                    return false;
                });
            }
        },false)
    }
}

function parseElement(elemPath) {
    while (Array.isArray(elemPath.match(/^(.*?)(:eq\(\d+\))/g))&&elemPath.match(/^(.*?)(:eq\(\d+\))/g).length) {
        let firstMatch=elemPath.match(/^(.*?)(:eq\(\d+\))/g)[0]
        let eqMatch=firstMatch.match(/(:eq\(\d+\))/g)[0]
        let eqDigit=parseInt(eqMatch.match(/\d+/g)[0])
        let parsed=firstMatch.replace(/(:eq\(\d+\))/g,':nth-child('+(eqDigit+1)+')')
        elemPath=elemPath.replace(firstMatch,parsed)
    }
    return elemPath
}