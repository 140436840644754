import Cookies from "js-cookie";
export function initAutoTrackingCore(lib) {
  return function(obj) {
    const client = this;
    const helpers = lib.helpers;
    const utils = lib.utils;
    const options = utils.extend({
      ignoreDisabledFormFields: false,
      ignoreFormFieldTypes: ['password'],
      recordClicks: true,
      recordClicksPositionPointer: true,
      recordFormSubmits: true,
      recordPageViews: true,
      recordPageViewsOnExit: false,
      recordScrollState: true,
      shareUuidAcrossDomains: false,
      collectIpAddress: true,
      collectUuid: true,
      recordElementViews: true,
      catchError: undefined // optional, function(someError) - error handler
    }, obj);

    if (client.config.requestType === 'beaconAPI' && options.catchError) {
      throw `You cannot use the BeaconAPI and catchError function in the same time, because BeaconAPI ignores errors. For requests with error handling - use requestType: 'fetch'`;
      return;
    }

    if (
      client.config.requestType === 'jsonp' // jsonp is deprecated, it's the default value from old keen's client
    ) {
      if (options.catchError) {
        client.config.requestType = 'fetch';
      } else {
        client.config.requestType = 'beaconAPI';
      }
    }

    let now = new Date();
    let allTimeOnSiteS = 0;
    let allTimeOnSiteMS = 0;
    if(typeof document !== 'undefined') {
      let hidden;
      let visibilityChange;
      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }

      const handleVisibilityChange = () => {
        if(document[hidden]) {
          allTimeOnSiteS += getSecondsSinceDate(now);
          allTimeOnSiteMS += getMiliSecondsSinceDate(now);
          return;
        } 
        now = new Date();
      }
      if(typeof document.addEventListener !== "undefined" ||
         hidden !== undefined){
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
      }
    }


    if(Cookies.get('sw')){
      Cookies.remove('sw')
    }

    let scrollState = {};
    if (options.recordScrollState) {
      scrollState = helpers.getScrollState();
      utils.listener('window').on('scroll', () => {
        scrollState = helpers.getScrollState(scrollState);
      });
    }



    client.extendEvents(function() {
      const browserProfile = helpers.getBrowserProfile();
      return {
        local_time_full: +new Date(),
        page: {
          title: document ? document.title : null,
          description: browserProfile.description,
          scroll_state: scrollState,
          time_on_page: allTimeOnSiteS > 0 ? allTimeOnSiteS : getSecondsSinceDate(now),
          time_on_page_ms: allTimeOnSiteMS > 0 ? allTimeOnSiteMS : getMiliSecondsSinceDate(now)
        },
        tech: {
          profile: browserProfile
        },

        url: {
          full: window ? window.location.href : '',
          info: {/* Enriched */}
        },

        referrer: {
          full: document ? document.referrer : '',
          info: {/* Enriched */}
        },

        schubwerk: {
          timestamp: +new Date()
        }
      };
    });

    if (options.recordClicks === true) {
      utils.listener('a, a *,button:not([type=submit])').on('click', function(e) {
        const el = e.target;
        let event = {
          element: helpers.getDomNodeProfile(el),
          local_time_full: new Date().toISOString(),
        };

        // pointer position tracking
        if(options.recordClicksPositionPointer === true) {
          const pointer = {
            x_position: e.clientX,
            y_position: e.clientY,
            scroll_x: window.scrollX,
            scroll_y: window.scrollY,
            window_width: window.innerWidth,
            window_height: window.innerHeight,
          }
          event = {...event, pointer};
        }

        if (options.catchError) {
          return client.recordEvent({
              collection: 'clicks',
              event
            }).catch(err => {
              options.catchError(err);
            });
        }

        return client.recordEvent({
            collection: 'clicks',
            event
          });
      });
    }

    if (options.recordFormSubmits === true) {
      utils.listener('form').on('submit', function(e) {
        const el = e.target;
        const serializerOptions = {
          disabled: options.ignoreDisabledFormFields,
          ignoreTypes: options.ignoreFormFieldTypes
        };
        const event = {
          form: {
            action: el.action,
            fields: utils.serializeForm(el, serializerOptions),
            method: el.method
          },
          element: helpers.getDomNodeProfile(el),
          local_time_full: new Date().toISOString()
        };
        if (options.catchError) {
          return client
            .recordEvent({
              collection: 'form_submissions',
              event
            })
            .catch(err => {
              options.catchError(err);
            });
        }
        return client.recordEvent({
          collection: 'form_submissions',
          event
        });
      });
    }

    if (options.recordPageViews === true && !options.recordPageViewsOnExit) {
      if (options.catchError) {
        client
          .recordEvent({
            collection: 'pageviews'
          })
          .catch(err => {
            options.catchError(err);
          });
      } else {
        client
          .recordEvent({
            collection: 'pageviews'
          });
      }
    }

    if (options.recordPageViewsOnExit && typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        client.config.requestType = 'beaconAPI'; // you can run beforeunload only with beaconAPI
        client.recordEvent({
          collection: 'pageviews'
        });
      });
    }

    if(options.recordElementViews === true){
      if(typeof IntersectionObserver !== 'undefined'){
        const elementViewsOptions = {
          threshold: 1.0,
        }
        const elementViewsCallback = (events, observer) => {
          events.forEach(el => {
            if(el.isIntersecting){
              const event = {
                element: helpers.getDomNodeProfile(el.target),
                local_time_full: new Date().toISOString()
              }
              if (options.catchError) {
                return client
                  .recordEvent({
                    collection: 'element_views',
                    event
                  }).catch(err => {
                    options.catchError(err);
                  });
              }

              return client
                .recordEvent({
                  collection: 'element_views',
                  event
                });
            }
          })
        }
        const observer = new IntersectionObserver(elementViewsCallback, elementViewsOptions);
        const target = document.querySelectorAll('.track-element-view');
        target.forEach(el => {
          observer.observe(el);
        });
        client.observers.IntersectionObserver = observer;
      }
    }

    return client;
  };
}

function getSecondsSinceDate(date) {
  return Math.round(getMiliSecondsSinceDate(date) / 1000);
}

function getMiliSecondsSinceDate(date) {
  return new Date().getTime() - date.getTime();
}
